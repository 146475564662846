type TokenStatus = "validating" | "valid" | "invalid";
import { Match, Show, Switch, createEffect, createSignal, onCleanup, splitProps } from "solid-js";
import { createPasswordResetInputs } from "../forms";
import { PasswordResetViewProps } from "../models";
import { useParams } from "@solidjs/router";
import { resetPassword, validateToken } from "../apis";
import { Atom, Cmp, state } from ":mods";
import { ROUTES } from "../const";

export function PasswordReset(props: PasswordResetViewProps){
	const [local, others] = splitProps(props, ["$"]);
	const ResetPasswordInputs = createPasswordResetInputs();
	const [verifing, setVerifing] = createSignal<boolean>(false)
	let [status, setStatus] = createSignal<TokenStatus>("validating");
	const $token_verified = state.create<boolean>(false);
	const params = useParams<{ token: string}>();

	createEffect(() => {
		checkToken()
	})

	const checkToken = async ()=>{
		setVerifing(true);
		await validateToken(params.token)
		.then((res)=>{
			console.log("Res:: ", res)
			setStatus("valid")
			local.$.actions.pushToast({
				type: "success",
				message: "Token has been verified!",
			});
			setVerifing(false)
			$token_verified.set(true);

		})
		.catch((res)=>{
			console.log("Res:: ", res);
			setStatus("invalid");

			local.$.actions.pushToast({
				type: "error",
				message: "Invalid Token",
			});
			setVerifing(false)
			$token_verified.set(false);
			
		})
	}

	const onSubmit = async () =>{
		const values = ResetPasswordInputs.Actions.getValuesLowercase();
		await resetPassword(params.token, values.confirm_password)
		.then((res)=>{
			console.log("Res:: ", res)
			local.$.actions.pushToast({
				type: "success",
				message: "Password has been reset!",
			});
			local.$.actions.navigate(ROUTES.auth.login);
		})
		.catch(()=>{
			local.$.actions.pushToast({
				type: "error",
				message: "Invalid Token",
			});
		})
	
	}


	return (
		<section class="flex flex-col !font-rubik$ w-full h-full items-center justify-center">
		<Switch>
{/* Checking token */}
		<Match when={verifing()}>
			<div class="flex flex-col !font-rubik$  h-fit bg#pt text#p rounded-lg p-40px w-60% mx-auto">
			<Atom.Loader.Platform
					title="Museum Taining Exhibition"
					msg="Verifing token..."
					class=" flex$col extend$ fight$ p-10px gap-10px"
				/>
			</div>
		</Match>
	
			
			{/* Show inputs filed when verified token , else show Invalid or expired token message */}
			<Match when={!verifing()}>
				<Show when={$token_verified.value} 
				fallback={
					<div class="flex flex-col !font-rubik$  h-fit bg#pt text#p rounded-lg p-40px w-60% mx-auto">
					<Atom.Loader.Platform
							title=""
							msg="Token has been expired"
							class=" flex$col extend$ fight$ p-10px gap-10px"	/>
							
					</div>
				}>
					<form class="flex flex-col !font-rubik$ w-493px h-fit bg#pt text#p rounded-lg p-40px ">
								<h1 class={`flex$ extend$w trim$ fit$h fight$ font-700 font-bold  capitalize text-31px mb-20px`}>Reset Password</h1>
								<div class="flex flex-col gap-12px w-full py-5">
								<div class="flex flex-col gap-5px">
												<ResetPasswordInputs.Password.Input
													autocomplete="current-password"
													type="password"
													class="form-input"
													placeholder="Password"
													id="password"
												/>
												<Show when={ResetPasswordInputs.Password.Error}>
													<ResetPasswordInputs.Password.Error
														class={`${ResetPasswordInputs.Password.control.isTouched ? "form-input-hint" : "form-input-error"}`}
													/>
												</Show>
							</div>
							<div class="flex flex-col gap-5px">
												<ResetPasswordInputs.ConfirmPassword.Input
													autocomplete="current-password"
													type="password"
													class="form-input"
													placeholder="Confirm Password"
													id="confirm-password"
												/>
												<Show when={ResetPasswordInputs.ConfirmPassword.Error}>
													<ResetPasswordInputs.ConfirmPassword.Error
														class={`${ResetPasswordInputs.ConfirmPassword.control.isTouched ? "form-input-hint" : "form-input-error"}`}
													/>
												</Show>
								</div>
						</div>

								<ResetPasswordInputs.Actions.Button
									class="form-btn flex$ min-h-2rem text-0.8rem px-2 py-1 fight$ my-24px"
									onclick={onSubmit}
									statusValid={"Reset Password"}
									statusInvalid={"Reset Password"}	/>
					</form>
				</Show>
			</Match>

		</Switch>
	</section>

	)

};
